<div class="row mt-3 center">
    <section class="emergency">
        <div class="container">
            <p>If you need any emergency service, simply call our 24 hour emergency number</p>
            <h2>Reliable Emergency Service with Fair rates</h2>
            <div class="oval-div">
                <span><a class="oval" href="tel:+91-9422-44-6375">Call: +91-9422-44-6375</a></span>
            </div>
        </div>
    </section>
</div>