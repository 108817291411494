import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './modules/shared/pages/dashboard/dashboard.component';

// Define Routes constant
const appRoutes: Routes = [
  // Site routes goes here
  { path: '', component: DashboardComponent, loadChildren: () => import('./modules/shared/shared.module').then(mod => mod.SharedModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
