<section>
    <div class="team-boxed">
        <div class="container">
            <div>
                <h2>How <span class="heading">We Work</span></h2>
            </div>
            <div class="row">
                <div class="col-sm-3 item">
                    <div class="card">
                        <span class="rounded-circle"><i class="fa fa-phone red-color fa-big"></i></span>
                        <div class="card-body">
                            <h4 class="card-title">Call Us/Send Mail</h4>
                            <p class="card-text">For home appliance repair, feel free to contact us either by giving a call or send a mail with details about your address.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3 item">
                    <div class="card">
                        <span class="rounded-circle"><i class="fa fa-fighter-jet red-color fa-big"></i></span>
                        <div class="card-body">
                            <h4 class="card-title">Quick Response</h4>
                            <p class="card-text">As soon as we receive a call or mail from your end, we response instantly for your query and start process to offer service.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3 item">
                    <div class="card">
                        <span class="rounded-circle"><i class="fa fa-wrench red-color fa-big"></i></span>
                        <div class="card-body">
                            <h4 class="card-title">Fix a Schedule</h4>
                            <p class="card-text">You are free to schedule an appointment for your issue/concern according to your timing and details about your address.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3 item">
                    <div class="card">
                        <span class="rounded-circle"><i class="fa fa-list-alt red-color fa-big"></i></span>
                        <div class="card-body">
                            <h4 class="card-title">We Fix Problems</h4>
                            <p class="card-text">After reaching your address, Our team will diagnose the problem and fix it depending on the issue successfully in real time.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>