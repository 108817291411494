<div class="row mt-3 center">
    <section class="contactus">
        <div class="container">
            <div class="row">
                <div class="col-sm-3 item">
                    <div class="card">
                        <img src="../../../../../assets/img/customerservice1.jpg" alt="RR">
                        <div class="card-body">
                            <p class="card-text">Customer Support Centre is the name to know for the best in class washing machine, refrigerator, and microwave oven repairs and services in Delhi. With years of experience under our belts, we with our skillful and trained professionals
                                make it a priority to offer reliable appliance repair services to accommodate your needs.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3 item">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title text-white card-title-font">CONTACT US</h4>
                            <span class="rounded-circle"><i
                                    class="fa fa-location-arrow white-color"></i>&nbsp;&nbsp;Near
                                cine
                                Vishant,
                                Behind Sundew Apartments, Opposite Shantadurga store, Reliance Refrigeration
                                shop.</span>
                            <br>
                            <span class="rounded-circle"><i class="fa fa-phone white-color"></i>&nbsp;&nbsp;9422446375 /
                                88051 72163</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3 item">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title text-white card-title-font">QUICK LINKS</h4>
                            <ul class="contact-list">
                                <li><a>Our Services</a></li>
                                <li><a>Why Choose Us</a></li>
                                <li><a>How We Work</a></li>
                                <li><a>Contact Us</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3 item">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title text-white card-title-font">GALLERY</h4>
                            <div class="row">
                                <div class="column">
                                    <img src="../../../../assets/img/why-choose-us-bckgd-img.jpg" alt="Girl in a jacket" width="200" height="600">
                                </div>
                                <div class="column">
                                    <img src="../../../../assets/img/customerservice1.jpg" alt="Girl in a jacket" width="400" height="600">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>