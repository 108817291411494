<section>
    <div class="container">
        <div class="heading">
            <h2>Our <span>Services</span></h2>
            <p>With a team of highly trained professional technicians, we provide top-notch reliable repairs and services for your washing machines, refrigerators, and microwaves in a timely manner</p>
        </div>
        <div class="row">
            <div class="card-deck">
                <div class="card">
                    <img class="card-img-top" src="../../../../../assets/img/rr3.jpg" alt="Bologna">
                    <div class="card-body">
                        <h4 class="card-title">Refrigerator</h4>
                        <h6 class="card-subtitle mb-2 text-muted">All types Refrigerators repairs & services</h6>
                        <p class="card-text">Your refrigerator - no matter whether it is single door, double door or a large one from any make and model, may require repairing services and regular maintenance.
                        </p>
                    </div>
                </div>

                <div class="card">
                    <img class="card-img-top" src="../../../../../assets/img/rr8.jpg" alt="Bologna">
                    <div class="card-body">
                        <h4 class="card-title">Air Conditioner</h4>
                        <h6 class="card-subtitle mb-2 text-muted">All types Air Conditioner repair & services</h6>
                        <p class="card-text">Due to dust and dirt, chances of AC filters are higher for clogging and stop working - mainly to cool the space. Getting proper cooling is important.</p>
                    </div>
                </div>

                <div class="card">
                    <img class="card-img-top" src="../../../../../assets/img/rr9.jpg" alt="Bologna">
                    <div class="card-body">
                        <h4 class="card-title">Air Cooler</h4>
                        <h6 class="card-subtitle mb-2 text-muted">All types Air Cooler repair & services</h6>
                        <p class="card-text">Air cooling is a method of dissipating heat. It works by expanding the surface area or increasing the flow of air over the object to be cooled, or both.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-5">
            <div class="card-deck">
                <div class="card">
                    <img class="card-img-top" src="../../../../../assets/img/watercooler.jpg" alt="Bologna">
                    <div class="card-body">
                        <h4 class="card-title">Water Cooler</h4>
                        <h6 class="card-subtitle mb-2 text-muted">All types Water Cooler repair & services</h6>
                        <p class="card-text">A water dispenser, known as water cooler, is a machine that cools or heats up and dispenses water with a refrigeration unit. It is commonly located near the restroom.
                        </p>
                    </div>
                </div>

                <div class="card">
                    <img class="card-img-top" src="../../../../../assets/img/wm1.jpg" alt="Bologna">
                    <div class="card-body">
                        <h4 class="card-title">Washing Machine</h4>
                        <h6 class="card-subtitle mb-2 text-muted">All types Washing Machine repair & services</h6>
                        <p class="card-text">A washing machine is used to wash laundry. The term is mostly applied to machines that use water as opposed to dry cleaning.
                        </p>
                    </div>
                </div>

                <div class="card">
                    <img class="card-img-top" src="../../../../../assets/img/df7.jpg" alt="Bologna">
                    <div class="card-body">
                        <h4 class="card-title">Deep Freezer</h4>
                        <h6 class="card-subtitle mb-2 text-muted">All types Deep Freezer repair & services</h6>
                        <p class="card-text">A deep freezer uses much lower temperatures, often in excess of 20 degrees below 0 and colder in commercial units but always below 0 Degree</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>