<!-- <p>header works!</p> -->

<nav class="navbar-top navbar-dark bg-dark" navbar>
    <a class="navbar-brand"> <i class="fa fa-phone fa-sm"></i> +91-9422-44-6375
    </a>
</nav>

<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="logosection left-logo-margin">
        <div class="col-lg-3 col-sm-12 col-md-3">
            <a href="#" title="RR logo">
                <img src="../../../../../assets/img/rr5.ico" alt="RR logo" class="img-responsive">
            </a>
        </div>
    </div>

    <div class="left-nav-margin">
        <ul class="navbar-nav">
            <li class="nav-item active">
                <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#">Features</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#">Pricing</a>
            </li>
            <li class="nav-item">
                <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
            </li>
        </ul>
    </div>
</nav>