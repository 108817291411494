import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRouting } from './shared.routing';

import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HeaderComponent } from './header/header-top/header.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderNavBarComponent } from './header/header-nav-bar/header-nav-bar.component';
import { RRImageCarouselComponent } from './pages/rr-image-carousel/rr-image-carousel.component';
import { RrServicesCardsComponent } from './pages/rr-services-cards/rr-services-cards.component';
import { RrEmergencyDetailsComponent } from './pages/rr-emergency-details/rr-emergency-details.component';
import { RrWhyChooseUsComponent } from './pages/rr-why-choose-us/rr-why-choose-us.component';
import { RrHowWeWorkComponent } from './pages/rr-how-we-work/rr-how-we-work.component';

@NgModule({
    declarations: [
        // Layout Components
        DashboardComponent,
        HeaderComponent,
        FooterComponent,
        HeaderNavBarComponent,
        RRImageCarouselComponent,
        RrServicesCardsComponent,
        RrEmergencyDetailsComponent,
        RrWhyChooseUsComponent,
        RrHowWeWorkComponent
    ],
    imports: [
        CommonModule,
        SharedRouting],
    exports: [
        // Layout Components
        DashboardComponent,
        HeaderComponent,
        FooterComponent
    ],
    providers: [
        SharedRouting
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedModule { }