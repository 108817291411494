<section>
    <div class="team-boxed">
        <div class="container">
            <div>
                <h2>Why <span class="heading">Choose Us</span></h2>
            </div>

            <div class="row">
                <div class="col-md-4 col-lg-4 item">
                    <div class="card">
                        <span class="rounded-circle"><i class="fa fa-check-circle red-color fa-big"></i></span>
                        <h3 class="name">Verified Service</h3>
                        <p class="description">Every professional at Customer Supporting Service, are background verified during their on-boarding to ensure the safety of our customers and to improve the quality of trust.</p>
                    </div>
                </div>
                <div class="col-md-4 col-lg-4 item">
                    <div class="card">
                        <span class="rounded-circle"><i class="fa fa-bitcoin red-color fa-size"></i></span>
                        <h3 class="name">Unbeatable price</h3>
                        <p class="description">Our charges are fixed and never backed by any kind of hidden cost. We never ask for extra charges in case of emergency hours. We are available 24×7 at fixed charges.
                        </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-lg-4 item">
                    <div class="card">
                        <span class="rounded-circle"><i class="fa fa-clock-o red-color fa-clock-size"></i></span>
                        <h3 class="name">Repairing services on time</h3>
                        <p class="description">Timely service and commitment to fix the problems in secure way are two key points – set us apart from others for offering you home appliance repairing services.
                        </p>
                    </div>
                </div>
                <div class="col-md-4 col-lg-4 item">
                    <div class="card">
                        <span class="rounded-circle"><i class="fa fa-lock red-color fa-secure-size"></i></span>
                        <h3 class="name">Secure Services</h3>
                        <p class="description">We have a pool of mechanics and engineers, who are certified and background checked to ensure you are getting repairing services from experts, who are verified and reliable.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>