import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rr-services-cards',
  templateUrl: './rr-services-cards.component.html',
  styleUrls: ['./rr-services-cards.component.css']
})
export class RrServicesCardsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
